export const navs = [
    {
        title: "contact",
        links: [
            { name: "github", url: "githubLink" },
            { name: "email", url: "emailLink" },
            { name: "instagram", url: "instagramLink" },
            { name: "linkedin", url: "linkedinLink" },
        ],
    },
];
