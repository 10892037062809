import React from 'react';
import Parallax from "./components/parallax/Parallax";

const App = () => {
  return (
    <div>
        <Parallax />
    </div>
  );
}

export default App;
